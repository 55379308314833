interface VersionData {
  version: number;
  lastDeploy: string;
}

// Default version if file doesn't exist
const defaultVersion: VersionData = {
  version: 1,
  lastDeploy: new Date().toISOString()
};

// Import the version.json file directly
let versionData: VersionData;
try {
  versionData = require('../version.json');
} catch {
  versionData = defaultVersion;
}

export const getCurrentVersion = (): number => {
  return versionData.version;
}; 