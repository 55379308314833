'use client';

import { useEffect } from 'react';

const RECAPTCHA_CLIENT_SITE_KEY = "6Ld7BoYqAAAAAOLkq35AeQajXoUCDJcPRa_EvmR4";

export default function ReCaptchaInitializer() {
  useEffect(() => {
    const loadRecaptchaScript = async () => {
      if (typeof window === 'undefined') return;

      return new Promise<void>((resolve, reject) => {
        if (window.grecaptcha) {
          window.grecaptcha.ready(() => resolve());
          return;
        }

        const timeoutId = setTimeout(() => {
          reject(new Error('reCAPTCHA failed to load after 10 seconds'));
        }, 10000);

        const script = document.createElement('script');
        script.src = `https://www.google.com/recaptcha/api.js?render=${RECAPTCHA_CLIENT_SITE_KEY}`;
        script.async = true;
        script.defer = true;
        script.id = 'recaptcha-script';

        script.onload = () => {
          clearTimeout(timeoutId);
          window.grecaptcha?.ready(() => resolve());
        };

        script.onerror = () => {
          clearTimeout(timeoutId);
          reject(new Error('Failed to load reCAPTCHA script'));
        };

        document.head.appendChild(script);
      });
    };

    const init = async () => {
      try {
        await loadRecaptchaScript();
        console.log('reCAPTCHA script loaded successfully!');
      } catch (error) {
        console.error('Error initializing reCAPTCHA:', error);
      }
    };

    // Add CSS to hide the badge
    const style = document.createElement('style');
    style.innerHTML = `
          .grecaptcha-badge { 
            visibility: hidden;
          }
        `;
    document.head.appendChild(style);

    init();
  }, []);

  return null;
} 