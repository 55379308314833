import { Spinner } from '@/components/shared/spinner';

interface LoadingScreenProps {
  opacity?: number;
}

const LoadingScreen = ({ opacity = 1 }: LoadingScreenProps) => {
  return (
    <div className={`fixed inset-0 bg-white flex justify-center items-center z-50`} style={{ opacity: opacity }}>
        <Spinner className="h-8 w-8 text-gray-500" />
    </div>
  );
};

export default LoadingScreen;
