import React, { useState } from 'react'
import { getCurrentVersion } from '@/utils/version';

function DeployedVersionLabel() {
  
    const [version] = useState(() => getCurrentVersion());

    return (
    <div className="fixed top-0 left-0 z-50 text-black px-2 py-1 text-[9px] opacity-50 hover:opacity-100 transition-opacity ">
    v{version}
  </div>
  )
}

export default DeployedVersionLabel